import './Banner.css';
import React, {useState, useEffect} from 'react';
import adconfig from './banner_configs.json';

function Banner() {
    const [conf, setConf] = useState(undefined);

    useEffect(() => {
        // Get random config when the component loads
        const index = getRandomIndex(adconfig.length);
        setConf(adconfig[index])
    }, []);

    function getRandomIndex(max) {
        return Math.floor(Math.random() * max);
    }

    return (
        <>
            {conf !== undefined ?
            <>
            <a href={conf.url} target="_blank" rel="noreferrer">
                <img
                    className="img-dsk my-10"
                    src={conf.img.dsk}
                    alt={conf.alt}
                ></img>
            </a>
            <a href={conf.url} target="_blank" rel="noreferrer">
                <img
                    className="img-mob my-10"
                    src={conf.img.mob}
                    alt={conf.alt}
                ></img>
            </a>
            </>
            : ""}
        </>
    );
}

export default Banner;
