import './Footer.css';

function Footer() {
    function getRangeText() {
        // Automated for all next years
        return ((new Date()).getFullYear() > 2023) ? ` - ${(new Date()).getFullYear()}` : ""
    }

    return (
        <footer className="bg-gray-800 text-center lg:text-left mt-auto">
            <div className="p-4 text-center text-white">
            Copyright © 2023{getRangeText()} . Self-affirmations.net . All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
