import './AboutPage.css';
import {getBennerEveryNItems} from '../../common/utils';
import sources from './sources.json';

function AboutPage() {
    return (
        <div className="text-left p-10 md:px-20">
            <h1 className="pb-10">Welcome to <span className="colored">self-affirmations.net</span>.</h1>

            <p className="">
                Practicing self-affirmations in front of a mirror greatly increases it's effects, but
                often times we don't have the mirror with us. Fortunately we usually carry a device with the
                selfie-camera. With that in mind I've developed this website which uses a camera to
                present a mirror, so you can practice wherever you are.
                <br />
                <br />
                - Aleš
            </p>

            <h2 className="py-10 colored">Sources</h2>

            <p className="pb-5">I collected affirmations from the following articles:</p>
            <ul id="links-list">
                {sources.map(function(source, i) {
                    if (source.title.trim()) {
                        return (
                            <>
                                {getBennerEveryNItems(i, 8)}
                                <li className='ml-5 pl-2'>
                                    <a key={`source-${i}`} href={source.link} target="_blank" rel="noreferrer">{source.title.trim()}</a>
                                </li>
                            </>
                        )
                    }
                })}
            </ul>
        </div>
    );
}

export default AboutPage;
