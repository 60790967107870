import './Menu.css';
import React from 'react';
import {NavLink} from "react-router-dom";

function Menu() {

    return (
        <>
            <nav className="bg-gray-800">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">
                        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    <NavLink to="/" className={`rounded-md px-3 py-2 text-sm font-medium ${({isActive}) => (isActive ? "active" : "")}`}>App</NavLink>
                                    <NavLink to="/topics" className={`rounded-md px-3 py-2 text-sm font-medium ${({isActive}) => (isActive ? "active" : "")}`}>Topics</NavLink>
                                    <NavLink to="/about" className={`rounded-md px-3 py-2 text-sm font-medium ${({isActive}) => (isActive ? "active" : "")}`}>About</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Menu;
