import './Mirror.css';
import React, {useState, useRef} from 'react';

import Webcam from 'react-webcam'

// const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}

function Mirror() {
    const [cameraWarning, setCameraWarning] = useState(true);
    const webcamRef = useRef(null)

    function showCameraWarning(er) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(er);
        }
        setCameraWarning(true);
    }

    function hideCameraWarning(er) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(er);
        }
        setCameraWarning(false);
    }
    return (
        <div className="pb-3 md:pb-5">
            <div>
                <Webcam
                    className="webcam"
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={400}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    mirrored={true}
                    onUserMedia={hideCameraWarning}
                    onUserMediaError={showCameraWarning}
                />  
            </div>
            {cameraWarning ? <div className="camera-warning">Turn on the camera and use it as a mirror</div> : ""}
        </div>
    );
}
  
export default Mirror;
  