import './AppPage.css';
import Mirror from '../../components/mirror/Mirror';
import Affirmations from '../../components/affirmations/Affirmations';

function AppPage({affirmations}) {
    return (
        <>
            <Mirror />
            <Affirmations affirmations={affirmations}/>
        </>
    );
}

export default AppPage;
