import Banner from '../components/banner/Banner';

export function getBennerEveryNItems(i, n=10) {
    return (i > 0 && i % n === 0) ?
        <>
            <Banner
                key={`banner-${i}`}
                className='align-middle'
            />
        </> : "";
}
