import './TopicsPage.css';
import {NavLink} from 'react-router-dom';
import {getBennerEveryNItems} from '../../common/utils';
import React, {useState, useEffect, Fragment} from 'react';

function TopicsPage({topics, topicsHandler}) {
    const [selectedTotal, setSelectedTotal] = useState(0);  // Save indices to make sure not to repeat affs

    useEffect(() => {
        countSelected()
    }, [topics]);

    useEffect(() => {
        countSelected()
        topicsHandler({...topics});
    }, []);

    function countSelected() {
        let counts = Object.values(topics).map(config => {
            return config.selected ? config.count : 0;
        });
        if (counts.every(element => element === 0)) {
            counts = Object.values(topics).map(config => config.count);
        }
        let selectedCount = counts.reduce((a, b) => a + b, 0);
        // console.log('counts:', counts)
        setSelectedTotal(selectedCount)
    }

    function toggleTopic(topic, isSelected) {
        let updatedTopics = {...topics};
        updatedTopics[topic].selected = isSelected;
        topicsHandler(updatedTopics);
    }

    function getSortedTopics() {
        // This will not work until Firefox v115 is released (on July 4th 2023)
        // return Object.keys(topics).toSorted();

        // Using alternative solution until then
        let topic_keys = Object.keys(topics);
        topic_keys.sort();
        return topic_keys;
    }

    return (
        <>
            <h2 className='title'>Select Topics</h2>
            <p className='hint-text'>(changes save automatically)</p>
            <p className='hint-text'>selected: {selectedTotal}</p>
            <p className='hint-text'>* if you buy something from the ads or create an account on these websites, I get a small commission</p>
            <br />
            <ul className='topics-list'>
                {getSortedTopics().map(function(topic, i) {
                    return (
                        <>
                            {getBennerEveryNItems(i, 8)}
                            <li key={`topic-${i}`} className={`topic-item ${topic} mb-5`}>
                                <input
                                    type="checkbox"
                                    className='larger-checkbox align-middle'
                                    id={`topic-${topic}`}
                                    checked={topics[topic].selected}
                                    onChange={() => toggleTopic(topic, !topics[topic].selected)}
                                />
                                <label htmlFor={`topic-${topic}`}>
                                    {topics[topic].text} <span className='count-text'>({topics[topic].count} affirmations)</span>
                                </label>
                            </li>
                        </>
                    );
                })}
            </ul>
            <NavLink className='link-back-to-app rounded-lg border-2 p-2' to="/">Go back to App</NavLink>
        </>
    );
}

export default TopicsPage;
