import './Affirmations.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';


function Affirmations({affirmations}) {
    const [statement, setStatement] = useState("");  // Current statement
    const [indices, setIndices] = useState([]);  // Save indices to make sure not to repeat affs

    useEffect(() => {
        // Get random index when the component loads
        setRandomStatement();
    }, []);

    function setRandomStatement() {
        let randomIndex = 0;
        let lastNIndices = [...indices];
        while(true) {
            randomIndex = Math.floor(Math.random() * affirmations.length);
            if (!lastNIndices.includes(randomIndex)) {
                lastNIndices.push(randomIndex);  // Add index to the end
                break;
            }
        }

        // Keep the index of (at most) last 100 indices
        let halfOfAffirmationsLength = Math.min(Math.floor(affirmations.length/2), 100);
        if (lastNIndices.length > halfOfAffirmationsLength) {
            lastNIndices.shift();  // Remove first
        }

        setIndices(lastNIndices);
        setStatement(affirmations[randomIndex]);
    }

    return (
        <>
            <p id='statements'>{statement}</p>
            <Link className='invisible md:visible link-next rounded-lg border-2 py-1 px-2' onClick={setRandomStatement}>Next</Link>
            <Link className='visible md:invisible link-next mt-auto w-full rounded-lg border-2 py-1 px-2' onClick={setRandomStatement}>Next</Link>
        </>
    );
}

export default Affirmations;
