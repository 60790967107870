import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, {useState, useEffect, useCallback} from 'react';
import Menu from './components/menu/Menu';
import AboutPage from './pages/about/AboutPage';
import AppPage from './pages/app/AppPage';
import Footer from './pages/footer/Footer';
import TopicsPage from './pages/topics/TopicsPage';

import en_affirmations from './en_affirmations.json';


function App() {
  const [allTopics, setAllTopics] = useState({});
  const [selectedAffirmations, setSelectedAffirmations] = useState([]);

  useEffect(() => {
    let topicsSpecs = {};
    for (let topic in en_affirmations) {

      topicsSpecs[topic] = {
        selected: false,
        text: en_affirmations[topic]['topicText'],
        count: en_affirmations[topic]['items'].length
      }
    }
    setAllTopics(topicsSpecs);
  }, []);

  useEffect(() => {
    let affirmations = [];
    Object.keys(allTopics).forEach(slug => {
      if (allTopics[slug].selected) {
        en_affirmations[slug].items.forEach(aff => {
          affirmations.push(aff)
        });
      }
    });
    if (affirmations.length === 0) {
      // if none is selected, select everything
      Object.keys(allTopics).forEach(slug => {
        en_affirmations[slug].items.forEach(aff => {
          affirmations.push(aff)
        });
      });
    }
    setSelectedAffirmations(shuffle(affirmations))
  }, [allTopics]);

  const allTopicsCallback = useCallback(() => {
    setAllTopics((topics) => { return {...topics}; });
  }, []);

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  return (
    <BrowserRouter basename="/">
      <div className="App flex flex-col h-screen bg-gray-900">
        <Menu />
        <main className="App-body flex flex-col bg-gray-900 md:mt-10 h-auto">
          <Routes>
            <Route exact path="/" element={<AppPage className="bg-gray-800" affirmations={selectedAffirmations} />}></Route>

            <Route exact path="/topics" element={<TopicsPage className="bg-gray-800" topics={allTopics} topicsHandler={allTopicsCallback}/>}></Route>

            <Route exact path="/about" element={<AboutPage className="bg-gray-800" />}></Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
